<template>
  <div id="container">

    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">5</div>
            <p>入塾金の<br>支払いへ進む</p>
          </div>
        </div>

        <form @submit="nextClicked">
          <div class="cts_box">
            <p class="blue bold">「*」のついている箇所は必須項目です。</p>
          </div>
          <div class="cts_box">
            <h2 class="sub_ttl"><span>パスワード設定</span></h2>
            <p class="reg_lead">
              ご希望のパスワードを8文字以上の半角英数記号で入力してください。<br>
              <span class="red bold">※パスワードには他の人に想像されにくい文字列を使用してください。<br>
            パスワードはログイン後の「マイページ」から変更可能です。</span>
            </p>
            <form-error-box v-if="errors"/>

            <div class="input_set">
              <div class="label">パスワード<span>*</span></div>
              <p>（8文字以上の半角英数記号）</p>
              <div class="pswd_box flex">
                <input v-bind:type="password_type ? 'text' : 'password'" v-model="new_user.password" class="password"
                       @input="new_user.password = replaceFullToHalf(new_user.password)"
                       placeholder="8文字以上" v-bind:class="{error: validation_errors.password}">
                <div class="checkbox">
                  <input id="check01" type="checkbox" v-model="password_type" value="1">
                  <label for="check01">入力内容を表示</label>
                </div>
              </div>
              <validation-errors :errors="validation_errors.password"
                                 v-if="validation_errors.password"></validation-errors>
            </div>
            <div class="input_set">
              <div class="label">確認のためにもう一度入力<span>*</span></div>
              <p>（8文字以上の半角英数記号）</p>
              <div class="pswd_box flex">
                <input v-bind:type="password_confirmation_type ? 'text' : 'password'"
                       @input="new_user.password_confirmation = replaceFullToHalf(new_user.password_confirmation)"
                       v-model="new_user.password_confirmation" class="password" placeholder="8文字以上"
                       v-bind:class="{error: validation_errors.password_confirmation}">
                <div class="checkbox">
                  <input id="check02" type="checkbox" v-model="password_confirmation_type" value="1">
                  <label for="check02">入力内容を表示</label>
                </div>
              </div>
              <validation-errors :errors="validation_errors.password_confirmation"
                                 v-if="validation_errors.password_confirmation"></validation-errors>
            </div>
          </div>

          <div class="cts_box">
            <h2 class="sub_ttl"><span>複眼経済塾を何で知りましたか？</span></h2>

            <div class="input_set survey" v-if="site_knows">
              <div class="label">以下の項目からお選びください（複数可）<span>*</span></div>
              <div class="checkbox c_red" v-for="site_know in site_knows" :key="site_know.id">
                <input v-bind:id="'survey_id' + site_know.id" class="checks" type="checkbox" v-model="ids"
                       v-bind:value="site_know.id">
                <label v-bind:for="'survey_id' + site_know.id">{{ site_know.name }}</label>
              </div>
              <validation-errors :errors="validation_errors.questionnaire"
                                 v-if="validation_errors.questionnaire"></validation-errors>
            </div>

            <p class="alnC sp_alnL mt40 mb20">
              すべての項目に入力後こちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="登録内容の確認">
            </div>
          </div>
        </form>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'
import FormErrorBox from '@/elements/FormErrorBox.vue'

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    FormErrorBox
  },
  data() {
    if (!this.$store.state.user) {
      if (this.$route.query.preview) {
        this.$store.state.user = { rank: 'rank_regular_month' }
      } else {
        this.$router.push({
          name: 'UsersRegistration',
          params: this.$route.params
        })
        return
      }
    }
    let _new_user = {}
    for (const [key, value] of Object.entries(this.$store.state.user)) {
      _new_user[key] = value
    }
    return {
      new_user: _new_user,
      validation_errors: {},
      errors: null,
      password_type: false,
      password_confirmation_type: false,
      site_knows: null,
      ids: [],
    }
  },
  mounted() {
    this.startAjax()
    this.axios
        .get(`${this.env.api_base_url}site_knows.json`)
        .then(response => {
          // console.log(response.data)
          this.site_knows = response.data.site_knows
        })
        .catch((response) => {
          this.errorAjax(response)
        })
        .finally(() => {
          this.finishAjax()
        })
  },
  methods: {
    prevClicked() {
      this.$store.state.user.address = this.new_user.address
      this.$router.push({
        name: 'UsersConfirmationPage2',
        params: this.$route.params
      })
    },
    nextClicked(event) {
      const keys = [
        'password', 'password_confirmation'
      ]

      event.preventDefault()
      this.validation_errors = {}
      this.errors = null

      this.new_user.questionnaire_ids = this.ids.join(',')
      this.$store.state.user = this.new_user
      this.new_user.password_validates = true

      this.axios
          .post(`${this.env.api_base_url}users/validate.json`, {
            user: this.new_user,
          })
          .then(() => {
            //
          })
          .catch((error) => {
            this.scrollTop()
            if (error.response.data.errors) {
              keys.forEach(key => {
                if (error.response.data.validation_errors[key]) {
                  this.validation_errors[key] = error.response.data.validation_errors[key]
                }
              })
              if (Object.keys(this.validation_errors).length > 0) {
                this.errors = true
              }
            }
            if (error.response.data && error.response.data.error) {
              // TODO: エラー処理
              // this.errors = [error.response.data.error]
            }
          })
          .finally(() => {
            // アンケート
            if (this.ids.length === 0) {
              this.validation_errors['questionnaire'] = ['最低1つは選択してください。']
              this.errors = true
            }
            if (this.errors) {
              this.scrollTop()
            } else {
              this.$router.push({
                name: 'UsersConfirmationConfirm',
                params: this.$route.params
              })
            }
            this.$forceUpdate();
          })
    }
  }
}
</script>
